.admin_panel_main {
    min-height: 100vh;
}

.admin_panel_brand_menu {
    display: flex;
    margin: 10px;
    cursor: pointer;
}

.admin_panel_brand_menu h1 {
    color: #fff;
    margin: 10px;
    letter-spacing: 2px;
}

.admin_panel_brand_menu img {
    max-width: 50px;
}

.admin_panel_side_menu ul {
    color: white;
}

.admin_panel_side_menu {
    
    position: fixed;
    background: #af65f5;
    width: 20vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.admin_panel_side_menu ul {
    margin-top: 20em;
}

.admin_panel_side_menu li {
    font-size: 20px;
    padding: 10px 40px;
    color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
    letter-spacing: 2px;
    transition: .3s;
}


.admin_panel_side_menu li:nth-child(1){
    background-color: white;
    border-radius: 20px;
    margin: 10px;
    color: black;
    transition: .3s;
}

.admin_panel_side_menu li:nth-child(2):hover {
    color: rgb(255, 117, 117);
    font-weight: bold;
}


.admin_panel_container {
    position: absolute;
    right: 0;
    width: 80vw;
    min-height: 100vh;
    background: #f1f1f1;
}

.admin_panel_container .admin_panel_header {
    position: fixed;
    top: 0;
    right: 0;
    width: 80vw;
    height: 10vh;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.admin_panel_container .admin_panel_header .admin_panel_nav {
    width: 90%;
    display: flex;
    align-items: center;
}


.admin_panel_username p {
    font-size: 20px;
    letter-spacing: 2px;

}


.admin_panel_container .admin_panel_content {
    position: relative;
    margin-top: 10vh;
    min-height: 90vh;
    padding: 20px ;
}