
.cardhomeabout_section{
	width: 100%;
	min-height: 35vh;
    margin-top: 5rem;
}

.cardhomebabout_title{
	color: rgb(102,0,102);

}
.cardhomeabout_container{
	width: 80%;
	display: block;
	margin:auto;
	padding-top: 100px;
}
.cardhomeabout_content-section{
	float: left;
	width: 55%;
}
.cardhomeabout_image-section{
	float: right;
	width: 40%;
}
.cardhomeabout_image-section img{
	width: 100%;
	height: auto;
}
.cardhomeabout_content-section .cardhomeabout_title{
	text-transform: uppercase;
	font-size: 28px;
}
.cardhomeabout_content-section .cardhomeabout_content h3{
	margin-top: 20px;
	color:#5d5d5d;
	font-size: 24px;
}
.cardhomeabout_content-section .cardhomeabout_content p{
	margin-top: 10px;
	font-family: sans-serif;
	font-size: 20px;
	line-height: 1.5;
}
.cardhomeabout_content-section .cardhomeabout_content .cardhomeabout_button{
	margin-top: 30px;
}
.cardhomeabout_content-section .cardhomeabout_content .cardhomeabout_button a{
	background-color: #3d3d3d;
	padding:12px 40px;
	text-decoration: none;
	color:#fff;
	font-size: 25px;
	letter-spacing: 1.5px;
}


@media screen and (max-width: 768px){
	.cardhomeabout_container{
	width: 80%;
	display: block;
	margin:auto;
	padding-top:50px;
}
.cardhomeabout_content-section{
	float:none;
	width:100%;
	display: block;
	margin:auto;
}
.cardhomeabout_image-section{
	float:none;
	width:100%;
	
}
.cardhomeabout_image-section img{
	width: 100%;
	height: auto;
	display: block;
	margin:auto;
	margin-top: 20px;
}
.cardhomeabout_content-section .cardhomeabout_title{
	text-align: center;
	font-size: 19px;
}
.cardhomeabout_content-section .cardhomeabout_content .cardhomeabout_button{
	text-align: center;
}
.cardhomeabout_content-section .cardhomeabout_content .cardhomeabout_button a{
	padding:9px 30px;
}

}



