.footer_main {
    width: 100%;
}

footer {
    position: relative;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0 16px 70px -8px rgba(0, 0, 0, 0.25);
    border-radius: 20px 20px 0 0;
    transition: background 0.3s linear;
}

.footer_container {
    max-width: 1240px;
    padding: 30px 40px;
    margin: auto;
}

.content_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.footer_profil .footer_logo_area {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.footer_profil .footer_logo_area img {
    border-radius: 50%;
    max-width: 50px;
    border: 1px solid #fff;
}

.footer_logo_area .footer_logo_name {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0 1rem;
    color: #000;
}

.footer_desc_area {
    max-width: 320px;
    margin-bottom: 1.25rem;
}

.footer_desc_area p {
    color: #828282;
    font-weight: 400;
    line-height: 28px;
}

.footer_social_media a{
  color: #828282;
  margin-right: 22px;
  font-size: 25px;
}

.footer_social_media a:hover{
    color: #313131;
    transition: 0.2s linear;
}

.footer_service_area {
    display: flex;
}

.footer_service_header {
    margin-right: 40px;
}

li .footer_service_name {
    color: #000;
    font-weight: 600;
}

.footer_service_header li a {
    color: #828282;
    font-weight: 400;
}

.footer_service_header li a:hover{
    color: #313131;
}

.footer_service_header li {
    margin-bottom: 10px;
}

hr {
    height: 1px;
    border: none;
    background-color: #828282;
    margin-bottom: 1rem;
}

.footer_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer_copy_right {
    color: #828282;
    display: flex;
    align-items: center;
}

.footer_copy_right span {
    margin-left: 8px;
    font-weight: 400;
    letter-spacing: 0.135rem;
}

.footer_tou a {
    color: #828282;
    margin-left: 1.5rem;
}

.footer_tou a:hover {
    color: #313131;
}


@media screen and (max-width: 768px) {
    .content_footer {
        flex-wrap: wrap;
        justify-content: left;
    }
    .footer_service_area {
        flex-wrap: wrap;
        justify-content: left;
    }
    .footer_service_header {
        margin-left: 0;
        margin-right: 40px;
    }
    .footer_social_media {
        margin-bottom: 1rem;
    }
    .footer_bottom {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media screen and (max-width: 510px) {
    .footer_service_header {
        margin-bottom: 8px;
    }
    .footer_copy_right {
        margin-bottom: 8px;
    }
}