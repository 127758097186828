.cardhomeabout_contentr-section {
    float: right;
	width: 55%;
	margin-top: 2em;
}

.cardhomeabout_imager-section {
    float: left;
	width: 40%;
	margin-top: 2em;
}

.cardhomeabout_imager-section img{
	width: 100%;
	height: auto;
}

.cardhomeabout_contentr-section .cardhomeabout_title{
	text-transform: uppercase;
	font-size: 28px;
}
.cardhomeabout_contentr-section .cardhomeabout_content h3{
	margin-top: 20px;
	color:#5d5d5d;
	font-size: 24px;
}
.cardhomeabout_contentr-section .cardhomeabout_content p{
	margin-top: 10px;
	font-family: sans-serif;
	font-size: 20px;
	line-height: 1.5;
}

@media screen and (max-width: 768px){
.cardhomeabout_contentr-section{
	float:none;
	width:100%;
	display: block;
	margin:auto;
}
.cardhomeabout_imager-section{
	float:none;
	width:100%;
	
}
.cardhomeabout_imager-section img{
	width: 100%;
	height: auto;
	display: block;
	margin:auto;
}
.cardhomeabout_contentr-section .cardhomeabout_title{
	text-align: center;
	font-size: 19px;
}
.cardhomeabout_contentr-section .cardhomeabout_content .cardhomeabout_button{
	text-align: center;
}
.cardhomeabout_contentr-section .cardhomeabout_content .cardhomeabout_button a{
	padding:9px 30px;
}

}