.videoComponent_main {
    min-height: 100vh;
}

.videoComponent_container {
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 800px;
    position: relative;
    z-index: 1;
}

.videoComponent_container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2; 
}

.videoComponent_bg{
    position: absolute;
    top: 0;
    right: 0; 
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.videoComponent_bg video {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232e34;
}

.heroContent{
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.hero-h1 {
    color: #fff;
    font-size: 68px;
    text-align: center;
    display: flex;
    font-weight: 500;
}


.heroContent_headline {
    display: flex;
}

.hero-h1-typewirter {
    color: #fff;
    font-size: 68px;
    text-align: center;
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
}

.hero-h1-mobile {
    color: #fff;
    font-size: 68px;
    text-align: center;
    display: flex;
    font-weight: 500;
    display: none;
}

.heroContent_headline-mobile {
    display: flex;
    
    display: none;
}

.hero-h1-typewirter-mobile {
    color: #fff;
    font-size: 68px;
    text-align: center;
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    
    display: none;
}



@media  (max-width: 991px) {
    .hero-h1-mobile {
        font-size: 48px;
    }
    .hero-h1-typewirter-mobile {
        font-size: 48px;
    }

    .hero-h1-mobile {
      display: block;
    }

    .heroContent_headline-mobile {
        display: block;
    }

    .hero-h1-typewirter-mobile {
        display: block;
    }

    .hero-h1 {
        display: none;
      }
  
      .heroContent_headline {
          display: none;
      }
  
      .hero-h1-typewirter {
          display: none;
      }
}