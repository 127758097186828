.MobileHeader_Container{
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: white;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
}

.MobileHeader_Logo{
    position: absolute;
    top: 1.2rem;
    left: 1.5rem;
    background: white;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
}

.MobileHeader_Logo img {
    max-width: 50px;
    cursor: pointer;
}

.MobileHeader_Icon {
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: white;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
}

.MobileHeader_Wrapper {
    color: #660066;
}

.MobileHeader_Wrapper ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(06, 80px);
    text-align: center;
}

.MobileHeader_Wrapper ul a{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    color: #660066;
    cursor: pointer;
    font-weight: bold;
}

.MobileHeader_Wrapper ul a:hover{
    color: #660066;
    transition: 0.2s ease-in-out;
}

.MobileHeader_BtnWrapper {
    display: flex;
    justify-content: center;
}

.MobileHeader_BtnWrapper a {
    border-radius: 50px;
    background: transparent;
    padding: 16px 64px;
    color: #660066;
    font-size: 20px;
    outline: none;
    border: 2px solid #660066;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin-left: 50px;
}

.MobileHeader_BtnWrapper a:hover {
    transition: all 0.2s ease-in-out;
    background: #660066;
    color: white;
}

.MobileHeader_LanguageIcon {
    position: absolute;
    bottom: 10%;
    left: 5%;
    cursor: pointer;
    font-size: 25px;
}


@media screen and (max-width: 480px) {
    .MobileHeader_Wrapper ul{
        grid-template-rows: repeat(6, 60px);
    }
}

