.banner_main {
    min-height: 80vh;
    padding: 80px;
}

.banner_main_image {
    position: relative;
    border-radius: 10px;
    background: url(../../images/flugzeug.jpg) no-repeat center;
    background-size: cover;
    height: 500px;
    overflow: hidden;
}

.banner_background {
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 70%;
    background-color: rgba(102, 0, 102, 0.5);
    position: absolute;
    
}

.banner_background_border{
    background-color: transparent;
    width: 85%;
    height: 85%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 3px solid white;
}

.banner_container {
    text-align: center;
    color: #fff;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 18px;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media  (max-width: 991px) {
    .banner_container {
        font-size: 12px;
    }
    .banner_main {
        padding: 40px;
    }
}