.textanimation_main {
    min-height: 100vh;
}

/*.textanimation_hero_image {
    position: relative;
    height: 1000px;
    overflow: hidden;
    background: url(../../images/vacation.jpg) no-repeat center;
    background-size: cover;
}*/

.textanimation_container {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.textanimation_container span {
    text-transform: uppercase;
    display: block;
}

.textanimation_text1 {
    color: black;
    font-size: 60px;
    font-weight: 700;
    letter-spacing: 8px;
    margin-bottom: 20px;
    background: white;
    position: relative;
    animation: text 3s 1;
}

.textanimation_text2 {
    font-size: 30px;
    letter-spacing: 2px;
    color: #551a8b;
}

.mouse_icon{
    position: absolute;
    top: 90%;
    left: 49%;
    font-size: 30px;
    color: #551a8b;
    cursor: pointer;
    transition: .3s;
}

.mouse_icon:hover{
    font-size: 35px;
    transition: .3s;
}

@keyframes text {
    0% {
      color: white;
      margin-bottom: -40px;
    }
    30% {
      letter-spacing: 25px;
      margin-bottom: -40px;
    }
    85% {
      letter-spacing: 8px;
      margin-bottom: -40px;
    }
}

@media screen and (max-width: 768px) {
    .textanimation_text1 {
        font-size: 30px;
    }
    .textanimation_text2 {
        font-size: 20px;
    }
    .mouse_icon{
        left: 45%;
    }
}