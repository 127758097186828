.ring  {
    position: fixed;
    top: 0;
    left: 0;
    width: 38px;
    height: 38px;
   /* border: 2px solid #660066; */
     background: #6f3b9c54;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    will-change: width, height, transform, border;
    z-index: 10000;
    pointer-events: none;
}

 /*.dot {
   position: fixed;
   top: 50%;
   left: 50%;
   width: 8px;
   height: 8px;
   background-color: #660066;
   border-radius: 100%;
   transform: translate(-50%, -50%);
   z-index: 10000;
   pointer-events: none;
 }

 */

  @media screen and (max-width: 1200px) {
      .ring {
          display: none;
      }
      .dot {
          display: none;
      }
}