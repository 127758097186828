.purchaseCardExtra_main {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.purchaseCardExtra_tr td {
    padding: .8rem;
    font-size: 1.4rem;
}

.table_icon {
    color: #771f81;
    font-size: 2rem;
}

.purchaseCardExtra_tr td:nth-child(2) {
    letter-spacing: 1.5px;
    font-weight: 500;
}

.purchaseCardExtra_wrapper {
    max-width: 1090px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.purchaseCardExtra_table {
    background: #fff;
    width: 100%;
    padding: 30px 30px;
    position: relative;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.purchaseCardExtra_horizontal_line {
    display: none;
}

.purchaseCardExtra_table h1 {
    margin-bottom: 1em;
    font-weight: 500;
    font-size: 25px;
    letter-spacing: 2px;
}

@media screen and (max-width: 600px) {
    .purchaseCardExtra_tr td {
        display: block;
    }

    .purchaseCardExtra_tr td:nth-child(1) {
        background-color: #fff;
        color: #fff;
        text-align: center;
    }
    .purchaseCardExtra_horizontal_line {
        display: block;
    }
}