.testimonial_main {
    min-height: 100vh;
    display: grid;
    height: 100%;
    place-items: center;
}

.slider_img {
    max-width: 500px;
}

/*

.testimonial_wrapper {
    max-width: 1200px;
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.testimonial_wrapper .testimonial_box {
    background: rgb(246, 246, 246);
    border-radius: 20px;
    padding: 25px;
    width: calc(33% - 10px);
}

.testimonial_quote {
    font-size: 20px;
    color: #551a8b;
}

.testimonial_wrapper .testimonial_box .testimonial_content {
   padding-top: 10px;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.testimonial_wrapper .testimonial_box .testimonial_info .testimonial_name{
    font-size: 17px;
    font-weight: 600;
}

.testimonial_wrapper .testimonial_box .testimonial_info .testimonial_job{
    color: #551a8b;
    font-size: 16px;
    font-weight: 500;
}

.testimonial_wrapper .testimonial_box .testimonial_info .testimonial_stars {
    margin-top: 2px;
}

.star {
    color: #551a8b;
    font-size: 20px;
}

.testimonial_wrapper .testimonial_box .testimonial_content .testimonial_image{
    height: 75px;
    width: 75px;
    padding: 3px;
    border-radius: 50%;
}

.testimonial_wrapper .testimonial_box .testimonial_content .testimonial_image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}

@media (max-width: 1045px) {
  .testimonial_wrapper .testimonial_box {
      width: calc(50% - 10px);
      margin: 10px 0;
  }
}

@media (max-width: 702px) {
    .testimonial_wrapper .testimonial_box {
        width: 100%;
    }
}

*/