.projectpage_main {
    min-height: 100vh;
}

.projectpage_section {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
}

.projectpage_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.projectpage_bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
}

.projectpage_content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.projectpage_content .projectpage_textBox {
    position: relative;
    max-width: 600px;
}

.projectpage_content .projectpage_textBox h2 {
    color: #fff;
    font-size: 4em;
    line-height: 1.2em;
    font-weight: 700;
}

.projectpage_content .projectpage_textBox p {
    color: #fff;
    margin-top: 20px;
}

.projectpage_content .projectpage_textBox a {
    display: inline-block;
    margin-top: 20px;
    padding: 8px 20px;
    background: #fff;
    color: #333;
    border-radius: 40px;
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration: none;
}


.projectpage_content  .projectpage_imgBx {
    position: relative;
    z-index: 1;
    width: 600px;
    display: flex;
    justify-content: flex-end;
} 

.projectpage_content  .projectpage_imgBx img {
    max-width: 550px;
}


@media (max-width: 991px) {
    .projectpage_bg::before{
        display: none;
    }
    .projectpage_section {
        padding: 20px;
        padding-bottom: 120px;
    }
    .projectpage_content {
        margin-top: 100px;
        flex-direction: column;
    }
    .projectpage_content .projectpage_textBox {
        max-width: 100%;
    }
    .projectpage_content .projectpage_textBox h2 {
        font-size: 2.5em;
        margin-bottom: 15px;
    }
    .projectpage_content .projectpage_imgBx {
        max-width: 100%;
        justify-content: center;
    }
    .projectpage_content .projectpage_imgBx img {
        max-width: 450px;
    }
} 