header {
    position: absolute;
    background-color: rgba(255, 255, 255);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

.header header a  {
    text-decoration: none;
    transition: .3s;
 }


.header_navigation {
    display: flex;
}
.header_navigation li {
    list-style: none;
}

.header_navigation li a {
    position: relative;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    text-decoration: none;
    margin-left: 40px;
    transition: ease .2s;
    padding: 0 5px;
    color: rgb(92, 92, 92);
    
}

.header_navigation li a:hover {
    color: royalblue;
}

.header_navigation li a::after {
    content: "";
    position: absolute;
    background-color: royalblue;
    height: 3px;
    width: 0;
    left: 0;
    transition: 0.3s;
}

.header_navigation li a:hover:after{
    width: 100%;
}

/*
.header_navigation li a:hover{
    font-size: 17px;
    transition: ease .2s;
    text-shadow:  0 0 8px rgba(102, 0, 102, 0.5);
    font-weight: bold;
}
*/

.header img {
    max-width: 125px;
}

.header_mobileIcon {
    display: none;
}


.header_modal, .header_overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 9999;
  }
  
  .header_overlay {
      background: rgba(133, 133, 133, 0.239);
  }
  
  .modal_content {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 1.4;
      background: #f1f1f1;
      padding: 14px 28px;
      border-radius: 3px;
      max-width: 500px;
      min-width: 250px;
      border-radius: 20px;
  
  }
  
  .modal_close {
      position: absolute;
      top: 0;
      right: 0;
      margin: 15px;
      font-size: 22px;
      color: #660066;
      cursor: pointer;
  }
  
  .modal_content_languages {
      display: flex;
      margin-top: 20px;
      text-align: center;
      margin-left: 50px;
  }
  
  .modal_content_languages img {
      border-radius: 50px;
      width: 30px;
      cursor: pointer;
  }
  
  
  .modal_content_languages p {
      margin: 20px;
      cursor: pointer;
      font-weight: bold;
      font-size: 22px;
      letter-spacing: 0px;
      transition: 0.2s ease;
  }

  .modal_content_languages p:hover {
    letter-spacing: 3px;
  }
  


@media  (max-width: 991px) {
    header {
        padding: 10px 50px;
    }
    .header_navigation {
        display: none;
    }
    .header_navigation header a h1{
      font-size: 20px;
    }
    .header_mobileIcon {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
}

@media (max-width: 539px) {
    header {
        padding: 5px 25px;
    }
}