.loading_main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading_container {
    position: relative;
    min-height: 100vh;
    padding: 40px 100px;
    box-sizing: border-box;
    background-color: white;
    background-position: center;
    background-size: cover;
}

.loading_container img {
    margin-bottom: 20px;
    max-width: 250px;
}

.loading_loader {
    position: absolute;
}

@media screen and (max-width: 768px) {
    .loading_container img {
       max-width: 200px;
       position: absolute;
       left: 0;
       padding-left: 10%;
    }
}