.purchase_main {
  min-height: 100vh;
  overflow-x: hidden;
}

.purchase_main_img img {
  padding: 20px;
  margin-top: 150px;
  width: 100%;
  border-radius: 20px;
}

.purchase_main_img_mobile img{
   display: none;
   margin-top: 150px;
   
}

@media (max-width: 768px) {
  .purchase_main_img_mobile img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    border-radius: 20px;
 }
 .purchase_main_img img {
   display: none;
}
}