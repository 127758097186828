.datatable_main {
    display: grid;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}

.datatable_main table {
    border-collapse: collapse;
    box-shadow: 0 5px 10px gray;
    background-color: white;
    text-align: center;
}

.datatable_main thead {
    box-shadow: 0 5px 10px rgb(211, 211, 211);
}

.datatable_main th {
    padding: 1rem 2rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 0.7rem;
    font-weight: 900;
}

.datatable_main td {
    padding: 1rem 2rem;
}

.datatable_main .status {
  border-radius: 5px;
  color: white;
  padding: 0.2rem 1rem;
  text-align: center;
}

.status-success {
    background-color: #00AB66;
}

.status-process {
    background-color: #FFBA01;
}

.pdf {
    color: #4286f4;
    cursor: pointer;
}

.pdf:hover {
    text-decoration: underline #4286f4;
}