.login_main{
  height: 100vh;
  overflow: hidden;

}

.login_logo {
    position: absolute;
    top: 0;
    left: 0;
    margin: 20px;
}

.login_logo img {
    max-width: 150px;
    cursor: pointer;
}

.login_center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: white;
    border-radius: 10px;
    border: 1px solid black;
    box-shadow:  10px 5px 5px rgb(163, 163, 163);
}

.login_center h1 {
    text-align: center;
    padding: 0 0 20px 0;
    border-bottom: 1px solid black;
}


.login_center form {
    padding: 0 40px;
    box-sizing: border-box;
}

.login_txt_field {
    position: relative;
    border-bottom: 2px solid #adadad;
    margin: 30px 0;
}

.login_txt_field input {
    width: 100%;
    padding: 0 5px;
    height: 40px;
    font-size: 16px;
    border: none;
    background: none;
    outline: none;
}

.login_txt_field label {
    position: absolute;
    top: 50%;
    left: 5px;
    color: #adadad;
    transform: translateY(-50%);
    font-size: 16px;
    pointer-events: none;
}

.login_txt_field span::before {
    content: '';
    position: absolute;
    top: 40px;
    left: 0;
    width: 0%;
    height: 2px;
    background: #551a8b;
    transition: 0.3s;
}

.login_txt_field input:focus ~ label,
.login_txt_field input:valid ~ label {
    top: -5px;
    color: #551a8b;
    transition: 0.3s;
}

.login_txt_field input:focus ~ span::before,
.login_txt_field input:valid ~ span::before {
    width: 100%;
    transition: 0.3s;
}

.login_center form input[type="submit"]{
    width: 100%;
    height: 50px;
    border: 1px solid;
    background: #551a8b;
    border-radius: 25px;
    font-size: 18px;
    color: #e9f4fb;
    font-weight: 700;
    cursor: pointer;
    outline: none;
    margin-bottom: 0.5em;
}

.login_center form input[type="submit"]:hover{
   border-color: #551a8b;
   transition: .5s; 
}