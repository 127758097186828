.image_banner_main {
    min-height: 40vh;
    padding: 100px;
} 

.image_banner_image_container h1 {
    text-align: center;
}

.image_banner_image_container img {
    margin-top: 20px;
    border-radius: 20px;
}

.image_banner_image{
    width: 100%;
    height: auto;
    border-radius: 20px;
}

.image_banner_mobile_image {
    display: none;
    width: 100%;
    height: auto;
    border-radius: 20px;
}

@media (max-width: 991px) {
    .image_banner_image {
        display: none;
    }
    .image_banner_mobile_image {
        display: inline;
    }
    .image_banner_main {
        padding: 50px;
    }
}