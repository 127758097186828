@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

*{
  margin: 0;
  padding: 0;
  
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: 'Nunito Sans', sans-serif;
}

*::-webkit-scrollbar {
  width: 15px;
  background: transparent;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 100px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: #a5a5a5;
}

*::selection{
  color: #fff;
  background: #4D33B3;
}