*{
    
    text-decoration: none;
}

.contactForm_main {
    min-height: 100vh;
}

.contact_bg {
    height: 70vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)), url(../../images/contact.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact_bg h3 {
    margin-top: 3rem;
    font-size: 1.3rem;
    font-weight: 400;
}

.contact_bg h2 {
    font-size: 3rem;
    text-transform: uppercase;
    padding: 0.4rem 0;
    letter-spacing: 4px;
}

.contact_line div {
  margin: 0 0.2rem;
}

.contact_line div:nth-child(1),
.contact_line div:nth-child(3){
    height: 3px;
    width: 70px;
    background: #fff;
    border-radius: 5px;
}

.contact_line{
    display: flex;
    align-items: center;
}
.contact_line div:nth-child(2){
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
}

.contact_text{
    margin-top: 1.5rem;
    font-weight: 300;
    font-size: 20px;
    opacity: 0.9;
    max-width: 650px;
}

.contact_body{
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 1rem;
}

.contact_info{
    margin: 2rem 0;
    text-align: center;
    padding: 2rem 0;
}

.contact_info span{
    display: block;
}

.contact_info div{
    margin: 0.8rem 0;
    padding: 1rem;
}
 span .fas{
    font-size: 3rem;
    padding-bottom: 0.9rem;
    color: #6e3b9c;
}
.contact_info div  span:nth-child(2){
    font-weight: 550;
    font-size: 1.2rem;
}

.contact_info div  span:nth-child(3){
    margin-top: 10px;
    letter-spacing: 1px;
    
}

.contact_info div a{
    text-decoration: none;
    color: black;
}

.contact_info .contact_text{
    padding-top: 0.4rem;
}

.contact_form{
    padding: 5rem 0;
    border-top: 1px solid #c7c7c7;
}
.contact_form h1 {
    text-align: center;
    margin-bottom: 10px;
    letter-spacing: 2px;
    font-weight: 500;
}

.contact_form form{
    padding-bottom: 1rem;
}
.contact_form_control{
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 0.7rem;
    margin: 0.6rem 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    outline: 0;
    box-shadow: inset 8px 8px 8px #cdced1, inset
    -8px -8px 8px #fff;
    resize: none;
}
.contact_form_control:focus{
    box-shadow:  8px 8px 8px #888888, 
    -8px -8px 8px #fff;
    transition: 0.3s linear;
}
.contact_form form div{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.6rem;
}
.contact_send_btn{
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff;
    background: #6e3b9c;
    border: none;
    border-radius: 10px;
    padding: 0.7rem 1.5rem;
    cursor: pointer;
    transition: all 0.4s ease;
}
.contact_send_btn:hover{
    opacity: 0.8;
}

.contact_form > div{
    margin: 0 auto;
    text-align: center;
}

.map {
    min-height: 55vh;
}
@media screen and (min-width: 768px){
    .contact_bg .contact_text{
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
    .contact_info{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 992px){
    .contact_bg .contact_text{
        width: 50%;
    }
    .contact_form{
        display: grid;
        grid-template-columns: repeat(1);
        align-items: center;
    }
}

@media screen and (min-width: 1200px){
    .contact_info{
        grid-template-columns: repeat(4, 1fr);
    }
}