.pagenotfound_main {
    background-image: linear-gradient(to bottom right, #a245ffc7, #ea4affce);
}

.fourzerofour_page_wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.pagenotfound_logo {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    padding: 10px;
    animation: slidedown 0.5s ease-out 0.5s forwards;
    cursor: pointer;
}

.pagenotfound_logo img {
    max-width: 40px;
}

.pagenotfound_logo p {
    color: white;
    margin-left: 10px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 12px;
    letter-spacing: 2px;
}

.fourzerofour_page_wrap h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 320px;
    font-weight: 900;
    animation: fadein 0.8s ease-out 0.5s forwards;
    opacity: 0;
}

@keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.2;
    }
}

.fourzerofour_content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fourzerofour_content h2 {
    color: #fff;
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 100px;
    text-align: center;
    animation: slidedown 0.5s ease-out 0.5s forwards;
    transform: translateY(-200px);
    opacity: 0;
}

@keyframes slidedown {
    0% {
        transform: translateY(-200px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.fourzerofour_button {
    display: inline-block;
    padding: 20px 40px;
    background-color: #fff;
    color: #222;
    font-weight: 700;
    text-decoration: none;
    border-radius: 999px;
    font-size: 28px;
    transition: 0.4s ease-out;

    animation: slideup 0.5s ease-out 0.5s forwards;
    transform: translateY(200px);
    opacity: 0;
}

@keyframes slideup {
    0% {
        transform: translateY(200px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.fourzerofour_button:hover {
    color: #a245ffc7;
    box-shadow: 6px 6px rgba(0, 0, 0, 0.3);
}


@media  (max-width: 991px) {
    .fourzerofour_page_wrap h1 {
        font-size: 120px;
    }
    .fourzerofour_content h2 {
        font-size: 25px;
    }
    .fourzerofour_button {
        padding: 15px 30px;
        font-size: 20px;
    } 
}