.purchaseCard_main {
    min-height: 80vh;
    margin-top: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.cross_price {
  margin-top: 20px;
  color: rgb(173, 173, 173);
}

.cross_price  h3{
  text-decoration: line-through  red;
  
}


.purchase_wrapper{
    max-width: 1090px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.purchase_wrapper .purchase_table {
    background: #fff;
    width: calc(33% - 20px);
    padding: 30px 30px;
    position: relative;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.purchase_table .purchase_price_section {
    display: flex;
    justify-content: center;
}



.purchase_table .purchase_price_area {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    padding: 2px;
}

.purchase_aj_p {
    text-align: center;
}

.purchase_aj_des {
    color: grey;
    padding-bottom: 30px;
    font-size: 14px;
}

.purchase_table .purchase_premium {
    margin: -20px;
}

.purchase_premium {
  transform: scale(1.04);
}

.purchase_table {
  transition: .3s;
}

.purchase_table:hover {
  transform: scale(1.03);
  box-shadow: 0 5px 10px rgba(0,0,0,0.3);
  transition: .3s;
}

.purchase_price_area .purchase_inner_area {
    height: 100%;
    width: 100%;
    line-height: 117px;
    text-align: center;
    color: #000;
    position: relative;
}

.purchase_price_area .purchase_inner_area .purchase_text {
    font-size: 25px;
    font-weight: 400;
    position: absolute;
    top: -10px;
    left: 5px;
}

.purchase_price_area .purchase_inner_area .purchase_price {
    font-size: 45px;
    font-weight: 500;
    margin-left: 16px;
}

.purchase_table .purchase_package_name {
    width: 100%;
    height: 2px;
    margin: 35px 0;
    position: relative;
}

.purchase_table .purchase_features li {
    margin-bottom: 15px;
    list-style: none;
    display: flex;
    justify-content: space-between;
}

.purchase_features li .purchase_list_name {
    font-size: 17px;
  font-weight: 400;
}  

.purchase_features li .purchase_icon {
    font-size: 20px;
}

.purchase_features li .purchase_icon .check {
    color: #2db94d;
}

.purchase_features li .purchase_icon .cancel {
    color: #cd3241;
}

.purchase_table .purchase_btn{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .purchase_table .purchase_btn button{
    width: 80%;
    height: 50px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    border: none;
    outline: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

.purchase_table .purchase_btn button:hover{
    border-radius: 5px;
}

.purchase_basic ::selection,
.purchase_basic .purchase_price_area,
.purchase_basic .purchase_inner_area,
.purchase_basic .purchase_head_tab h2{
  color:#4564f8;
}

.purchase_basic .purchase_inner_area {
  color: black;
}

.purchase_basic .purchase_btn button{
    background: #4564f8;
    color: #fff;
    margin-top: -75px;
  }
  

  .purchase_premium ::selection,
  .purchase_premium .purchase_price_area,
  .purchase_premium .purchase_inner_area,
  .purchase_premium .purchase_head_tab h2{
    color:#771f81;
  }

  .purchase_premium .purchase_inner_area {
    color: black;
  }
  
  
  .purchase_premium .purchase_btn button{
    background: #771f81;
    color: #fff;
    margin-top: -75px;
  }
  
.purchase_premium .purchase_btn button:hover{
    background: #771f81;
    color: #fff;
}

.purchase_ultimate ::selection,
.purchase_ultimate .purchase_price_area,
.purchase_ultimate .purchase_inner_area,
.purchase_ultimate .purchase_head_tab h2{
  color:#6e55da;
}

.purchase_ultimate .purchase_inner_area {
  color: black;
}


.purchase_ultimate .purchase_btn button{
  background: #6e55da;
  color: #fff;
  margin-top: -75px;
}

.purchase_ultimate .purchase_btn button:hover{
  background: #6e55da;
  color: #fff;
}


@media (max-width:756px){
    .purchase_wrapper .purchase_table{
      width: 100%;
    }
  
    .purchase_table.purchase_premium{
      margin: 40px 0px;
    }
    .purchase_table.purchase_ultimate{
      margin: 0px;
    }
  }
  

  .purchase_table .purchase_ribbon{
    width: 150px;
    height: 150px;
    position: absolute;
    top: -10px;
    left: -10px;
    overflow: hidden;
  }
  
  .purchase_table .purchase_ribbon::before,
  .purchase_table .purchase_ribbon::after{
    position: absolute;
    content: "";
    z-index: -1;
    display: block;
    border: 7px solid #771f81;
    border-top-color: transparent;
    border-left-color: transparent;
  }
  
  .purchase_table .purchase_ribbon::before{
    top: 0px;
    right: 15px;
  }
  
  .purchase_table .purchase_ribbon::after{
    bottom: 15px;
    left: 0px;
  }
  
  .purchase_table .purchase_ribbon span{
    position: absolute;
    top:30px;
    right: 0;
    transform: rotate(-45deg);
    width: 200px;
    background: #771f81;
    padding: 10px 0;
    color: #fff;
    text-align: center;
    font-size: 17px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.12);
  }