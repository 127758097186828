* {
    outline: none;
}

.projectCard_main {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 100%;
  min-height:100vh;
}

.projectCard_wrapper {
    width: 80%;
    padding: 30px 0;
    text-align: center;
}

.projectCard_section_header {
    text-transform: uppercase;
    padding-bottom: 30px;
    font-size: 25px;
    color: #262626;
    letter-spacing: 3px;

}

.projectCard_content {
  padding: 10%;
}

.projectCard_icon {
    font-size: 35px;
    cursor: pointer;
    color: #551a8b;
}
  
.projectCard_main_content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-row-gap: 25px;
    grid-column-gap: 25px;
}

.projectCard_main_content .projectCard_box {
    position: relative;
    cursor: pointer;
    margin-bottom: 15px;
    box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.15);
    height: 250px;
    overflow: hidden;
}

.projectCard_main_content .projectCard_box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    transition: all 0.5s ease-in-out;
}

.projectCard_main_content .projectCard_box:hover::before {
   top: 0;
   right: calc(100% - 5px);
   z-index: 8;
}

.projectCard_main_content .projectCard_box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.projectCard_main_content .projectCard_box .projectCard_img_text {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    top: 0;
    right: 100%;
    transition: all 0.5s ease-in-out;
}

.projectCard_main_content .projectCard_box:hover .projectCard_img_text {
    top: 0;
    right: 0;
}