

.serviceCard_main section {
    min-height: 60vh;
    width: 100%;

    margin-top: 20em;
    display: grid;
    place-items: center;
}

.serviceCard_headline  h1{
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 10px;
    font-size: 30px;
    font-weight: 800;
    margin: 20px;
}

.serviceCard_row {
    display: flex;
    flex-wrap: wrap;
}

.serviceCard_column {
    width: 100%;
    padding: 0 1em 1em 1em;
    text-align: center;
}

.serviceCard_card {
    width: 100%;
    height: 100%;
    padding: 2em 1.5em;
    background: linear-gradient(#fff 50%, #551a8b 50%);
    background-size: 100% 200%;
    background-position: 0 2.5%;
    border-radius: 5px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.5s;
}

.serviceCard_card h3 {
    font-size: 22px;
    font-weight: 600;
    color: #1f194c;
    margin: 1em 0;
}

.serviceCard_card p {
    color: #575a7b;
    font-size: 19px;
    line-height: 1.6;
    letter-spacing: 0.03;
}

.serviceCard_iconWrapper {
    background-color: #551a8b;
    position: relative;
    margin: auto;
    font-size: 30px;
    height: 2.5em;
    width: 2.5em;
    color: #fff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: 0.5s;
}

.serviceCard_iconWrapper img {
    max-width: 40px;
    filter: invert(1);
}

.serviceCard_card:hover {
    background-position: 0 100%;
}

.serviceCard_card:hover .serviceCard_iconWrapper {
    background-color: #fff;
    color: #551a8b;
}
.serviceCard_card:hover .serviceCard_iconWrapper img {
    filter: invert(0);
}


.serviceCard_card:hover h3 {
    color: #fff;
}

.serviceCard_card:hover p {
    color: #f0f0f0;
}


@media screen and (min-width: 768px) {
    .serviceCard_main section {
        padding: 0 2em;
    }
    .serviceCard_column {
        flex: 0 50%;
        max-width: 50%;
    }
}

@media screen and (min-width: 992px) {
    .serviceCard_main section {
        padding: 1em 3em;
    }
    .serviceCard_column {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
}

@media (max-width: 668px) {
    .serviceCard_main section {
        margin-top: 10em;
    }
}