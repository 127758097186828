.faq_main {
     min-height: 100vh;
}


.accordian_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 100vh;
    background: #fff;
}


.accordian_container {
    box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);
    margin: 15px;
}

.accordian_wrap {
    background: white;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
    
    cursor: pointer;
}

.accordian_wrap h1 {
    padding: 2rem;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 1.5rem;
}

.accordian_dropdown {
    background: #fff;
    color: rgb(53, 53, 53);
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #551a8b;
    border-top: 1px solid #551a8b;
}

.accordian_dropdown p {
    font-size: 1.3em;
}

.icon_acc {
    font-size: 45px;
}

@media screen and (min-width: 768px){ 
   .accordian_wrap h1 {
       font-size: 1.3em;
}
   
.accordian_dropdown p {
    font-size: 1em;
}
}